.app {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
}

.header {
  margin: auto 0;
  height: 25%;
}

.logo {
  display: block;
  width: 100%;
  height: 100%;
}

.sparkles {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}