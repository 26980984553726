@font-face {
    font-family: 'Gajkley';
    src: url('../fonts/Gajkley.eot');
    src: url('../fonts/Gajkley.eot?#iefix') format('embedded-opentype'),
        // url('../fonts/Gajkley.woff2') format('woff2'),
        // url('../fonts/Gajkley.woff') format('woff'),
        // url('../fonts/Gajkley.ttf') format('truetype'),
        url('../fonts/Gajkley.svg#Gajkley') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}