@import "./fonts";

html, body, #root {
  position: relative;
  height: 100%;
}

body {
  background-color: #FF9955;
  margin: 0;
  // font-family: Gajkley, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
