.container {
    position: absolute;
    touch-action: none;

    div {
        position: absolute;
        width: 100px;
        height: 100px;
        margin-left: -50px;
        margin-top: -50px;
        pointer-events: none;
    }

    img {
        width: 100%;
        height: 100%;
        animation: animate 0.5s ease-in-out both;
        opacity: 0;
    }
}

@keyframes animate {
    0% {
        opacity: 0;
        transform: translateY(0);
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateY(300%);
    }
}